import { customersClient } from '@/api/api';
import { AuthComponent } from '@/auth/auth0';
import Vue from 'vue';
import { NavigationGuard } from 'vue-router';

export const nonStaffSingleCustomerGuard: NavigationGuard = async (to, from, next) => {
    const $auth: AuthComponent = Vue.prototype.$auth;

    if (to.name === 'home' && !$auth.isStaff) {
        const { customers, departments } = await customersClient.getCustomersByAssignedUser($auth.user.sub!);
        if (customers.length === 1 && departments.length === 0) {
            await next({ name: 'customer', params: { customerId: customers[0].id } });
            return;
        } else if (customers.length === 0 && departments.length === 1) {
            const { customerId, id } = departments[0];
            await next({ name: 'department', params: { customerId, departmentId: id } });
            return;
        }
    }

    await next();
};
