import Vue from 'vue';

class BackgroundSyncStore {
    private db?: IDBDatabase;
    count = 0;

    constructor() {
        const openDBRequest = window.indexedDB.open('workbox-background-sync');
        openDBRequest.onerror = e => console.error('could not open db', e);
        openDBRequest.onsuccess = async () => {
            this.db = openDBRequest.result;
            await this.syncCount();
        };
    }

    syncCount() {
        if (!this.db) {
            return;
        }

        if (![...this.db.objectStoreNames].includes('requests')) {
            return;
        }

        const tx = this.db.transaction(['requests'], 'readonly');
        const request = tx.objectStore('requests').count();
        request.onsuccess = () => this.count = request.result;
    }

    async executeOfflineRequests() {
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.getRegistration();

            if (registration?.active) {
                registration.active.postMessage({ type: 'SYNC' });
            }
        }
    }
}

export const backgroundSyncStore = Vue.observable(new BackgroundSyncStore());
