import {
    Auth0Client,
    Auth0ClientOptions,
    createAuth0Client,
    GetTokenSilentlyOptions,
    LogoutOptions,
    RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import Vue, { PluginObject } from 'vue';
import Component from 'vue-class-component';

export interface Auth0Options extends Auth0ClientOptions {
    onRedirectCallback(state: any): void,
}

let options!: Auth0Options;

export interface Auth0User {
    sub?: string;
    picture?: string;
    name?: string;

    [key: string]: any;
}

@Component
export class AuthComponent extends Vue {
    loading = true;
    isAuthenticated = false;
    user: Auth0User = {};
    auth0Client!: Auth0Client;
    error = null;

    get roles() {
        return this.user?.['http://ruedinger-oel.de/roles'] ?? [];
    }

    get isStaff() {
        return this.roles.includes('Staff');
    }

    get isSelfService() {
        return this.roles.includes('SelfService');
    }

    get isStaffOrSelfService() {
        return this.isStaff || this.isSelfService;
    }

    loginWithRedirect(o?: RedirectLoginOptions) {
        return this.auth0Client.loginWithRedirect(
            {
                ...o,
                authorizationParams: {
                    title: window.tenant.title,
                    logo: `${location.origin}/img/logos/${window.tenant.logo}`,
                },
            },
        );
    }

    async getTokenSilently(o?: GetTokenSilentlyOptions) {
        if (!this.auth0Client) {
            return localStorage.getItem('auth_token');
        }

        const token = await this.auth0Client.getTokenSilently(o);

        localStorage.setItem('auth_token', token);

        return token;
    }

    logout(o?: LogoutOptions['logoutParams']) {
        return this.auth0Client.logout({
            logoutParams: o,
        });
    }

    async created() {
        if (!navigator.onLine) {
            const fromStorage = localStorage.getItem('auth');

            if (fromStorage) {
                this.user = JSON.parse(fromStorage);
                this.isAuthenticated = true;
                this.loading = false;

                console.log('Authorized from local storage', this.user.name);

                return;
            }
        }

        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client(options);

        try {
            // If the user is returning to the app after authentication..
            if (
                window.location.search.includes('code=') &&
                window.location.search.includes('state=')
            ) {
                // handle the redirect and retrieve tokens
                const { appState } = await this.auth0Client.handleRedirectCallback();

                // Notify subscribers that the redirect callback has happened, passing the appState
                // (useful for retrieving any pre-authentication state)
                options.onRedirectCallback(appState);
            }
        } catch (e: any) {
            this.error = e;
        } finally {
            // Initialize our internal authentication state
            this.isAuthenticated = await this.auth0Client.isAuthenticated();
            this.user = (await this.auth0Client.getUser())!;
            this.loading = false;
        }

        if (this.isAuthenticated) {
            localStorage.setItem('auth', JSON.stringify(this.user));
        }
    }
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin: PluginObject<Auth0Options> = {
    install(Vue, o) {
        options = o!;
        Vue.prototype.$auth = new AuthComponent();
    },
};

declare module 'vue/types/vue' {
    interface Vue {
        $auth: AuthComponent;
    }
}
