import BaseLayout from '@/layout/BaseLayout.vue';
import RouteParent from '@/layout/RouteParent.vue';
import { Route, RouteConfig } from 'vue-router';

const machineRoutes: RouteConfig = {
    path: 'machine/:machineId',
    name: 'machine',
    props: true,
    component: () => import('../machines/views/MachineParentView.vue'),
    redirect: { name: 'view-machine' },
    meta: {
        breadcrumb: 'machine',
        icon: 'cog',
    },
    children: [
        {
            path: '',
            name: 'view-machine',
            component: () => import('../machines/views/MachineView.vue'),
            meta: {
                title: 'machine',
            },
        },
        {
            path: 'edit',
            name: 'edit-machine',
            component: () => import('../machines/views/EditMachineView.vue'),
            meta: {
                breadcrumb: 'edit-machine',
                icon: 'cog-edit',
            },
        },
        {
            path: 'add-measurement',
            name: 'add-measurement',
            component: () => import('../measurements/views/AddMeasurementView.vue'),
            props: bindQuery,
            meta: {
                breadcrumb: 'add-measurement',
                icon: 'vial-plus',
            },
        },
        {
            path: 'import-measurements',
            name: 'import-measurements',
            component: () => import('../measurements/views/ImportMeasurementsView.vue'),
            meta: {
                breadcrumb: 'import-measurement',
                icon: 'vial-arrow-up',
            },
        },
        {
            path: 'edit-measurement/:measurementId',
            name: 'edit-measurement',
            component: () => import('../measurements/views/EditMeasurementView.vue'),
            props: bindQuery,
            meta: {
                breadcrumb: 'edit-measurement',
                icon: 'vial-edit',
            },
        },
        {
            path: 'add-filling',
            name: 'add-filling',
            component: () => import('../machines/views/AddFillingView.vue'),
            meta: {
                breadcrumb: 'add-lubricant-filling',
                icon: 'fill-drip',
            },
        },
        {
            path: 'edit-filling/:fillingId',
            name: 'edit-filling',
            component: () => import('../machines/views/EditFillingView.vue'),
            meta: {
                breadcrumb: 'edit-filling',
                icon: 'fill-drip-edit',
            },
            props: true,
        },
        {
            path: 'qr-code',
            name: 'qr-code',
            component: () => import('../machines/views/QrCodeView.vue'),
            meta: {
                breadcrumb: 'qr-code',
                icon: 'qrcode',
            },
        },
        {
            path: 'add-product',
            name: 'add-machine-product',
            component: () => import('../machines/views/AddMachineProductView.vue'),
            meta: {
                breadcrumb: 'add-product',
                icon: 'cube-plus',
            },
        },
        {
            path: 'edit-product/:productId',
            name: 'edit-machine-product',
            component: () => import('../machines/views/EditMachineProductView.vue'),
            meta: {
                breadcrumb: 'edit-machine-product',
                icon: 'cube-edit',
            },
            props: true,
        },
    ],
};

const departmentRoutes: RouteConfig = {
    path: ':departmentId?',
    name: 'department',
    props: true,
    component: () => import('../departments/views/DepartmentParentView.vue'),
    redirect: { name: 'view-department' },
    meta: {
        breadcrumb: 'department',
        icon: 'users-cog',
    },
    children: [
        {
            path: '',
            name: 'view-department',
            component: () => import('../departments/views/DepartmentView.vue'),
            meta: {
                title: 'department',
            },
        },
        {
            path: 'edit',
            name: 'edit-department',
            component: () => import('../departments/views/EditDepartmentView.vue'),
            meta: {
                breadcrumb: 'edit-department',
                icon: 'users-cog-edit',
            },
        },
        {
            path: 'aggregations',
            name: 'aggregations',
            component: () => import('../customers/views/CustomerAggregationView.vue'),
            meta: {
                breadcrumb: 'aggregations',
                icon: 'chart-pie',
            },
        },
        {
            path: 'downloads',
            name: 'customer-downloads',
            component: () => import('../downloads/views/CustomerDownloadsView.vue'),
            meta: {
                breadcrumb: 'downloads',
                icon: 'file-download',
            },
        },
        {
            path: 'scan-qr',
            name: 'scan-qr',
            component: () => import('../customers/views/ScanQrCodeView.vue'),
            meta: {
                breadcrumb: 'scan-qr',
                icon: 'qrcode',
            },
        },
        {
            path: 'lab-import',
            name: 'lab-import',
            component: () => import('../customers/views/ImportLabReportView.vue'),
            meta: {
                breadcrumb: 'import-lab-report',
                icon: 'flask',
            },
        },
        {
            path: 'add-machine',
            name: 'add-machine',
            component: () => import('../machines/views/AddMachineView.vue'),
            meta: {
                breadcrumb: 'add-machines',
                icon: 'cog-plus',
            },
        },
        {
            path: 'tours/:tourId',
            name: 'tour',
            component: () => import('../tours/views/TourView.vue'),
            props: true,
            meta: {
                breadcrumb: 'measurements-report',
                icon: 'file-alt',
            },
        },
        {
            path: 'lab-reports/:tourId',
            name: 'lab-report',
            component: () => import('../tours/views/TourView.vue'),
            props: true,
            meta: {
                breadcrumb: 'lab-report',
                icon: 'flask',
            },
        },
        {
            path: 'add-fillings',
            name: 'add-fillings',
            component: () => import('../lubricants/views/AddMultipleFillingsView.vue'),
            meta: {
                breadcrumb: 'add-fillings',
                icon: 'fill-drip',
            },
        },
        machineRoutes,
    ],
};

const customerRoutes: RouteConfig = {
    path: 'customers',
    name: 'customers',
    component: RouteParent,
    redirect: { name: 'customers-list' },
    meta: {
        breadcrumb: 'customers',
        icon: 'address-book',
    },
    children: [
        {
            path: '',
            name: 'customers-list',
            component: () => import('../customers/views/CustomersView.vue'),
            meta: {
                title: 'customers',
            },
        },
        {
            path: 'add',
            name: 'add-customer',
            component: () => import('../customers/views/AddCustomerView.vue'),
            meta: {
                breadcrumb: 'add',
                title: 'add-customer',
                icon: 'plus',
            },
        },
        {
            path: ':customerId',
            name: 'customer',
            props: true,
            component: () => import('../customers/views/CustomerParentView.vue'),
            redirect: { name: 'view-customer' },
            meta: {
                breadcrumb: 'customer',
                icon: 'briefcase',
            },
            children: [
                {
                    path: '',
                    name: 'view-customer',
                    component: () => import('../customers/views/CustomerView.vue'),
                    meta: {
                        title: 'customer',
                    },
                },
                {
                    path: 'edit',
                    name: 'edit-customer',
                    component: () => import('../customers/views/EditCustomerView.vue'),
                    meta: {
                        breadcrumb: 'edit',
                        icon: 'briefcase-edit',
                    },
                },
                {
                    path: 'add-department',
                    name: 'add-department',
                    component: () => import('../departments/views/AddDepartmentView.vue'),
                    meta: {
                        breadcrumb: 'add-department',
                        icon: 'users-cog-plus',
                    },
                },
                departmentRoutes,
            ],
        },
    ],
};

export const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'base',
        component: BaseLayout,
        redirect: { name: 'home' },
        meta: {
            breadcrumb: 'home',
            icon: 'home',
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('../home/Home.vue'),
                meta: {
                    title: 'home',
                },
            },
            customerRoutes,
            {
                path: 'users',
                name: 'users',
                component: RouteParent,
                redirect: { name: 'users-list' },
                meta: {
                    breadcrumb: 'user',
                    icon: 'users',
                },
                children: [
                    {
                        path: '',
                        name: 'users-list',
                        component: () => import('../users/views/UsersView.vue'),
                        meta: {
                            title: 'users',
                        },
                    },
                    {
                        path: 'add',
                        name: 'add-user',
                        component: () => import('../users/views/AddUserView.vue'),
                        meta: {
                            breadcrumb: 'add',
                            title: 'add-user',
                            icon: 'user-plus',
                        },
                    },
                    {
                        path: ':userId',
                        name: 'edit-user',
                        component: () => import('../users/views/EditUserView.vue'),
                        props: true,
                        meta: {
                            breadcrumb: 'edit',
                            title: 'edit-user',
                            icon: 'user-edit',
                        },
                    },
                ],
            },
            {
                path: 'machine-types',
                name: 'machine-types',
                component: RouteParent,
                redirect: { name: 'machine-types-list' },
                meta: {
                    breadcrumb: 'machine-types',
                    icon: 'cogs',
                },
                children: [
                    {
                        path: '',
                        name: 'machine-types-list',
                        component: () => import('../machines/views/MachineTypesView.vue'),
                        meta: {
                            title: 'machine-types',
                        },
                    },
                    {
                        path: 'add',
                        name: 'add-machine-type',
                        component: () => import('../machines/views/AddMachineTypeView.vue'),
                        meta: {
                            breadcrumb: 'add',
                            title: 'add-machine-type',
                            icon: 'plus',
                        },
                    },
                ],
            },
            {
                path: 'lubricants',
                name: 'lubricants',
                component: RouteParent,
                redirect: { name: 'lubricants-list' },
                meta: {
                    breadcrumb: 'lubricants',
                    icon: 'tint',
                    staffOnly: true,
                },
                children: [
                    {
                        path: '',
                        name: 'lubricants-list',
                        component: () => import('../lubricants/views/LubricantsView.vue'),
                        meta: {
                            title: 'lubricants',
                        },
                    },
                    {
                        path: 'add',
                        name: 'add-lubricant',
                        component: () => import('../lubricants/views/AddLubricantView.vue'),
                        meta: {
                            breadcrumb: 'add',
                            title: 'add-lubricant',
                            icon: 'tint-plus',
                        },
                    },
                    {
                        path: ':lubricantId',
                        name: 'lubricant',
                        component: RouteParent,
                        meta: {
                            breadcrumb: 'lubricant',
                            icon: 'tint',
                        },
                        redirect: { name: 'view-lubricant' },
                        children: [
                            {
                                path: '',
                                name: 'view-lubricant',
                                component: () => import('../lubricants/views/LubricantView.vue'),
                                props: true,
                                meta: {
                                    title: 'lubricant',
                                },
                            },
                            {
                                path: 'edit',
                                name: 'edit-lubricant',
                                component: () => import('../lubricants/views/EditLubricantView.vue'),
                                props: true,
                                meta: {
                                    breadcrumb: 'edit',
                                    title: 'edit-lubricant',
                                    icon: 'tint-edit',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'products',
                name: 'products',
                component: RouteParent,
                redirect: { name: 'products-list' },
                meta: {
                    breadcrumb: 'other-products',
                    icon: 'cube',
                    staffOnly: true,
                },
                children: [
                    {
                        path: '',
                        name: 'products-list',
                        component: () => import('../products/views/ProductsView.vue'),
                        meta: {
                            title: 'other-products',
                        },
                    },
                    {
                        path: 'add',
                        name: 'add-product',
                        component: () => import('../products/views/AddProductView.vue'),
                        meta: {
                            breadcrumb: 'add',
                            title: 'add-product',
                            icon: 'cube-plus',
                        },
                    },
                    {
                        path: ':productId',
                        name: 'product',
                        component: RouteParent,
                        meta: {
                            breadcrumb: 'product',
                            icon: 'cube',
                        },
                        redirect: { name: 'view-product' },
                        children: [
                            {
                                path: '',
                                name: 'view-product',
                                component: () => import('../products/views/ProductView.vue'),
                                props: true,
                                meta: {
                                    title: 'product',
                                },
                            },
                            {
                                path: 'edit',
                                name: 'edit-product',
                                component: () => import('../products/views/EditProductView.vue'),
                                props: true,
                                meta: {
                                    breadcrumb: 'edit',
                                    title: 'edit-product',
                                    icon: 'cube-edit',
                                },
                            },
                        ],
                    },
                    {
                        path: 'group/add',
                        name: 'add-product-group',
                        component: () => import('../products/views/AddProductGroupView.vue'),
                        meta: {
                            breadcrumb: 'add-product-group',
                            icon: 'cubes-plus',
                        },
                    },
                    {
                        path: 'group/:groupId/edit',
                        name: 'edit-product-group',
                        component: () => import('../products/views/EditProductGroupView.vue'),
                        props: true,
                        meta: {
                            breadcrumb: 'edit-product-group',
                            icon: 'cubes-edit',
                        },
                    },
                ],
            },
            {
                name: 'anonymous-machines',
                path: '/share/:customerId/:departmentId?',
                component: () => import('../anonymous/views/AnonymousMachinesView.vue'),
                props: true,
                meta: {
                    nonAuthenticated: true,
                    icon: 'file',
                    breadcrumb: 'maintenance-sheet-view',
                },
            },
            {
                name: 'notification-settings',
                path: 'notification-settings',
                component: () => import('../users/views/MyNotificationSettings.vue'),
                meta: {
                    breadcrumb: 'notification-settings',
                    icon: 'bell',
                },
            },
            {
                name: 'privacy',
                path: 'privacy',
                component: () => import('../layout/PrivacyView.vue'),
                meta: {
                    breadcrumb: 'privacy',
                    icon: 'shield-alt',
                    nonAuthenticated: true,
                },
            },
            {
                name: 'open-source',
                path: 'open-source',
                component: () => import('../layout/OpenSourceView.vue'),
                meta: {
                    breadcrumb: 'open-source',
                    icon: 'code',
                    nonAuthenticated: true,
                },
            },
            {
                name: 'help-de',
                path: 'help-de',
                component: () => import('../layout/HelpView.vue'),
                meta: {
                    breadcrumb: 'help',
                    icon: 'question',
                    nonAuthenticated: true,
                },
            },
            {
                name: 'help-en',
                path: 'help-en',
                component: () => import('../layout/HelpViewEn.vue'),
                meta: {
                    breadcrumb: 'help',
                    icon: 'question',
                    nonAuthenticated: true,
                },
            },
            {
                path: '/logged-out',
                name: 'logged-out',
                component: () => import('../auth/LoggedOutView.vue'),
                meta: {
                    nonAuthenticated: true,
                    title: 'logged-out',
                },
            },
        ],
    },
    {
        path: '*',
        name: 'not-found',
        redirect: {
            name: 'home',
        },
    },
];

function bindQuery(route: Route) {
    return { ...route.params, ...route.query };
}
