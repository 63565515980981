import { notificationsClient } from '@/api/api';
import i18n from '@/i18n';
import { SnackbarProgrammatic } from 'buefy';
import { parseInt } from 'lodash-es';

const publicKey = 'BHhN4715M3r5DwiM0N3ks_4BLBCk088UgIZY363jl2JEVAAA52QgRLkpmtxj7M0Yx47GKgEn2aYkQUtjycJmAFg';

export type MappedSubscription = { endpoint: string; authSecret: string; key: string, device: string | null };

export function mapPushSubscription(subscription: PushSubscription): MappedSubscription {
    const rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
    const key = rawKey ? btoa(String.fromCharCode(...new Uint8Array(rawKey))) : '';
    const rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
    const authSecret = rawAuthSecret ? btoa(String.fromCharCode(...new Uint8Array(rawAuthSecret))) : '';
    const endpoint = subscription.endpoint;

    return { endpoint, key, authSecret, device: navigator.userAgent };
}

export async function postPushTokenIfNecessary(subscription: PushSubscription) {
    if (localStorage.getItem('pushTokenPosted')) {
        return;
    }

    await notificationsClient.addPushSubscription(mapPushSubscription(subscription));

    localStorage.setItem('pushTokenPosted', 'true');
}

export async function registerPush(registration: ServiceWorkerRegistration, forceToast = false) {
    const options: PushSubscriptionOptionsInit = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey),
    };

    const state = await registration.pushManager.permissionState(options);

    if (state === 'granted') {
        await createSubscription();
    } else if (state === 'prompt') {
        if (!forceToast) {
            const toastCount = parseInt(localStorage.getItem('pushToastCount') ?? '0', 10);

            if (toastCount >= 10) {
                return;
            }

            localStorage.setItem('pushToastCount', (toastCount + 1).toString());
        }

        await new Promise(resolve => {
            SnackbarProgrammatic.open({
                message: i18n.t('enable-push').toString(),
                actionText: i18n.t('activate-now').toString(),
                type: 'is-success',
                duration: 8_000,
                onAction: () => {
                    resolve(createSubscription());
                },
            });
        });
    } else {
        console.log('Notifications denied');
    }

    async function createSubscription() {
        console.log('Creating subscription');

        const subscription = await registration.pushManager.subscribe(options);
        await postPushTokenIfNecessary(subscription);
    }
}

function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
