












































































































































import { backgroundSyncStore } from '@/common/helpers/BackgroundSyncStore';
import { presentationModeStore } from '@/common/helpers/PresentationModeStore';
import { NavItem } from '@/layout/helpers/NavItem';
import NavigationItem from '@/layout/NavigationItem.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { NavigationItem },
})
export default class Navigation extends Vue {
    backgroundSyncStore = backgroundSyncStore;
    presentationModeStore = presentationModeStore;

    get tenant() {
        return window.tenant;
    }

    get customers() {
        return this.item('customers');
    }

    get users() {
        return this.item('users');
    }

    get lubricants() {
        return this.item('lubricants');
    }

    get products() {
        return this.item('products');
    }

    get machineTypes() {
        return this.item('machine-types');
    }

    get notificationSettings() {
        return this.item('notification-settings');
    }

    private item(name: string): NavItem {
        const { route: { matched } } = this.$router.resolve({ name });

        const resolved = matched[1];

        return {
            title: this.$t(resolved.meta.breadcrumb) as string,
            routeName: name,
            icon: resolved.meta.icon,
            active: !!this.$route.matched.find(record => record.name === name),
        };
    }

    setLocale(locale: string) {
        this.$i18n.locale = locale;
        localStorage.setItem('locale', locale);

        if (this.$route.name?.startsWith('help-')) {
            this.$router.replace({ name: `help-${locale}` });
        }
    }

    togglePresentationMode() {
        presentationModeStore.toggle();
        this.$buefy.toast.open({
            message: this.$t(presentationModeStore.isEnabled ? 'presentation-mode-enabled' : 'presentation-mode-disabled').toString(),
            type: 'is-success',
        });
    }

    logout() {
        this.$auth.logout({
            returnTo: window.location.origin + '/logged-out',
        });
    }
}
