import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook,
    faAngleLeft,
    faAngleRight,
    faArchive,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faBell,
    faBriefcase,
    faChartArea,
    faChartPie,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faCode,
    faCog,
    faCogs,
    faCopy,
    faCube,
    faCubes,
    faDirections,
    faDoorOpen,
    faDownload,
    faEdit,
    faEllipsisH,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faFile,
    faFileAlt,
    faFileDownload,
    faFileExport,
    faFillDrip,
    faFlask,
    faFolderOpen,
    faForward,
    faHome,
    faIdCardAlt,
    faInfoCircle,
    faKey,
    faLightbulb,
    faMinus,
    faPen,
    faPlus,
    faQrcode,
    faQuestion,
    faRulerHorizontal,
    faSave,
    faShieldAlt,
    faSignInAlt,
    faSignOutAlt,
    faStore,
    faSync,
    faTimesCircle,
    faTint,
    faTrash,
    faUpload,
    faUser,
    faUserEdit,
    faUserPlus,
    faUsers,
    faUsersCog,
    faVial,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faSignOutAlt, faHome, faUser, faPlus, faArrowUp, faArrowDown, faKey, faCopy,
    faIdCardAlt, faUsersCog, faUserPlus, faUserEdit, faBriefcase, faAddressBook,
    faTrash, faAngleLeft, faAngleRight, faEdit, faCog, faPen, faChartArea, faSave,
    faMinus, faExclamationCircle, faTint, faArchive, faFillDrip, faFolderOpen, faCube,
    faCheck, faExclamation, faVial, faTimesCircle, faUpload, faFlask, faCubes, faForward,
    faFileExport, faSync, faDirections, faFileAlt, faDoorOpen, faRulerHorizontal,
    faCogs, faChartPie, faEllipsisH, faFileDownload, faDownload, faBell, faQrcode, faLightbulb,
    faExchangeAlt, faInfoCircle, faShieldAlt, faSignInAlt, faChevronDown, faWrench, faFile,
    faCode, faCheckCircle, faUsers, faQuestion, faArrowRight, faStore,
);
