


































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PageHeader extends Vue {
    @Prop({ type: [Number, String] }) readonly size?: string | number;

    get titleTag() {
        if (!this.size) {
            return 'h2';
        }

        return 'h' + this.size;
    }

    get titleClasses() {
        if (!this.size) {
            return '';
        }

        return 'is-' + (parseInt(this.size.toString(), 10) + 1);
    }
}
