






























import Breadcrumbs from '@/layout/Breadcrumbs.vue';
import FooterLayout from '@/layout/FooterLayout.vue';
import Navigation from '@/layout/Navigation.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { FooterLayout, Breadcrumbs, Navigation },
})
export default class BaseLayout extends Vue {
}
