import { doWithDelayedLoading } from '@/common/helpers/loading';
import { showErrorToast } from '@/common/helpers/LoadingMixin';
import i18n from '@/i18n';

export abstract class StoreBase<T extends { id: string }> {
    private _loading = false;
    private promise = Promise.resolve();

    protected _value: T | null = null;

    protected constructor(private descriptionKey: string) {
    }

    get loading() {
        return this._loading;
    }

    get value() {
        return this._value;
    }

    unload() {
        this._value = null;
    }

    async load(id: string) {
        // ensure a loading in progress is awaited
        await this.promise;

        if (this.value?.id === id) {
            return;
        }

        this._value = null;

        this.promise = this.loadNow(id);
        await this.promise;
    }

    private async loadNow(id: string) {
        try {
            await doWithDelayedLoading(
                async () => this._value = await this.loadInternal(id),
                loading => this._loading = loading);
        } catch (e) {
            showErrorToast(e, i18n.t(this.descriptionKey).toString());
        }
    }

    async reload() {
        if (!this._value) {
            throw new Error('No value is loaded');
        }

        await this.loadNow(this._value.id);
    }

    protected abstract loadInternal(id: string): Promise<T>
}
