import { baseUrl } from '@/api/baseUrl';
import {
    AnonymousClient,
    CustomersClient, DepartmentsClient,
    LubricantFillingClient,
    LubricantsClient,
    MachinesClient,
    MachineToursClient,
    MachineTypesClient,
    MeasurementsClient,
    NotificationsClient,
    ProductGroupsClient,
    ProductsClient,
    TenantClient,
    UsersClient,
} from '@/api/clients';
import { ToastProgrammatic } from 'buefy';
import Vue from 'vue';

function createTimeoutSignal(): AbortSignal {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 5000);
    return controller.signal;
}

const fetchWrapper: { fetch: typeof window.fetch } = {
    async fetch(request, options): Promise<Response> {
        const accessToken = await Vue.prototype.$auth.getTokenSilently();

        try {
            return await window.fetch(request, {
                ...options,
                signal: options?.method === 'POST' ? createTimeoutSignal() : null,
                headers: {
                    ...options?.headers,
                    Authorization: 'Bearer ' + accessToken,
                },
            });
        } catch (e) {
            if ((e instanceof TypeError || e instanceof DOMException) && options?.method?.toUpperCase() === 'POST') {
                ToastProgrammatic.open({
                    message: 'Anfrage wird bis zu 24h lang zwischengespeichert und wiederholt, wenn eine Netzwerkverbindung möglich ist.',
                    type: 'is-warning',
                });

                return new Response(null, { status: 200 });
            } else {
                throw e;
            }
        }
    },
};

export const customersClient = new CustomersClient(baseUrl, fetchWrapper);
export const usersClient = new UsersClient(baseUrl, fetchWrapper);
export const machinesClient = new MachinesClient(baseUrl, fetchWrapper);
export const machineTypesClient = new MachineTypesClient(baseUrl, fetchWrapper);
export const measurementsClient = new MeasurementsClient(baseUrl, fetchWrapper);
export const lubricantsClient = new LubricantsClient(baseUrl, fetchWrapper);
export const productsClient = new ProductsClient(baseUrl, fetchWrapper);
export const productGroupsClient = new ProductGroupsClient(baseUrl, fetchWrapper);
export const lubricantFillingClient = new LubricantFillingClient(baseUrl, fetchWrapper);
export const notificationsClient = new NotificationsClient(baseUrl, fetchWrapper);
export const toursClient = new MachineToursClient(baseUrl, fetchWrapper);
export const tenantClient = new TenantClient(baseUrl, fetchWrapper);
export const anonymousClient = new AnonymousClient(baseUrl);
export const departmentsClient = new DepartmentsClient(baseUrl, fetchWrapper);
