










import { Component, Prop, Vue } from 'vue-property-decorator';

interface LayerDef {
    icon: string,
    transform: string
}

const layers: Record<string, LayerDef[]> = {
    'cog-plus': [
        { icon: 'cog', transform: 'shrink-2 up-3 left-5' },
        { icon: 'plus', transform: 'shrink-5 down-4 right-5' },
    ],
    'cog-edit': [
        { icon: 'cog', transform: 'shrink-2 up-2 left-5' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-5' },
    ],
    'vial-plus': [
        { icon: 'vial', transform: 'up-2 left-4' },
        { icon: 'plus', transform: 'shrink-5 down-4 right-5' },
    ],
    'vial-plus-plus': [
        { icon: 'vial', transform: 'up-2 left-4' },
        { icon: 'plus', transform: 'shrink-7 down-4 right-1' },
        { icon: 'plus', transform: 'shrink-7 down-4 right-9' },
    ],
    'vial-edit': [
        { icon: 'vial', transform: 'up-2 left-4' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-5' },
    ],
    'vial-arrow-up': [
        { icon: 'vial', transform: 'up-2 left-4' },
        { icon: 'arrow-up', transform: 'shrink-5 down-4 right-5' },
    ],
    'tint-plus': [
        { icon: 'tint', transform: 'shrink-2 down-2 left-4' },
        { icon: 'plus', transform: 'shrink-5 up-4 right-5' },
    ],
    'tint-edit': [
        { icon: 'tint', transform: 'shrink-2 up-2 left-5' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-3' },
    ],
    'cube-plus': [
        { icon: 'cube', transform: 'shrink-4 down-2 left-4' },
        { icon: 'plus', transform: 'shrink-5 up-4 right-6' },
    ],
    'cube-edit': [
        { icon: 'cube', transform: 'shrink-4 up-2 left-5' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-3' },
    ],
    'cubes-plus': [
        { icon: 'cubes', transform: 'shrink-4 down-2 left-4' },
        { icon: 'plus', transform: 'shrink-5 up-4 right-6' },
    ],
    'cubes-edit': [
        { icon: 'cubes', transform: 'shrink-4 up-2 left-5' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-3' },
    ],
    'fill-drip-edit': [
        { icon: 'fill-drip', transform: 'shrink-4 up-2 left-5' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-6' },
    ],
    'flask-plus': [
        { icon: 'flask', transform: 'shrink-2 down-2 left-4' },
        { icon: 'plus', transform: 'shrink-5 up-4 right-6' },
    ],
    'briefcase-edit': [
        { icon: 'briefcase', transform: 'shrink-3 up-2 left-4' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-5' },
    ],
    'threshold-too-high': [
        { icon: 'arrow-up', transform: 'shrink-3 up-2' },
        { icon: 'ellipsis-h', transform: 'shrink-7 down-6 left-5' },
        { icon: 'ellipsis-h', transform: 'shrink-7 down-6 right-4' },
    ],
    'threshold-too-low': [
        { icon: 'arrow-down', transform: 'shrink-3 down-4' },
        { icon: 'ellipsis-h', transform: 'shrink-7 up-4 left-5' },
        { icon: 'ellipsis-h', transform: 'shrink-7 up-4 right-4' },
    ],
    'users-cog-plus': [
        { icon: 'users-cog', transform: 'shrink-2 down-3 left-5' },
        { icon: 'plus', transform: 'shrink-5 up-4 right-6' },
    ],
    'users-cog-edit': [
        { icon: 'users-cog', transform: 'shrink-2 up-3 left-5' },
        { icon: 'pen', transform: 'shrink-5 down-4 right-5' },
    ],
};

@Component
export default class IconWrapper extends Vue {
    @Prop(String) readonly type?: string;
    @Prop(String) readonly size?: string;
    @Prop(Array) readonly icon?: string[];

    get layer(): null | LayerDef[] {
        if (!this.icon) {
            return null;
        }

        return layers[this.icon[1]] ?? null;
    }
}
