import { backgroundSyncStore } from '@/common/helpers/BackgroundSyncStore';
import { postPushTokenIfNecessary, registerPush } from '@/common/helpers/pushNotifications';
import i18n from '@/i18n';
import { NotificationProgrammatic, SnackbarProgrammatic } from 'buefy';
import { Workbox } from 'workbox-window';

export async function registerServiceWorker() {
    console.log('Service Worker registration requested');

    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        console.log('Registering Service Worker');

        const wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

        wb.addEventListener('installed', event => {
            console.log(event);
            if (event.isUpdate) {
                showUpdateToast();
            }
        });

        const registration = await wb.register();

        if (!registration) {
            return;
        }

        console.log('Service Worker ready');
        console.log('Checking push subscription');

        const subscription = await registration.pushManager.getSubscription();

        if (!subscription) {
            await registerPush(registration);
        } else {
            await postPushTokenIfNecessary(subscription);
        }

        navigator.serviceWorker.addEventListener('message', ({ data }) => {
            if (data.type === 'NOTIFICATION') {
                NotificationProgrammatic.open({
                    message: data.title,
                    type: 'is-black',
                    duration: 5_000,
                });
            } else if (data.type === 'REQUEST_QUEUED' || data.type === 'BACKGROUND_SYNC') {
                backgroundSyncStore.syncCount();
            }
        });
    }
}

function showUpdateToast() {
    SnackbarProgrammatic.open({
        message: i18n.t('update-available').toString(),
        actionText: i18n.t('update-now').toString(),
        indefinite: true,
        type: 'is-warning',
        onAction: () => location.reload(),
    });
}
