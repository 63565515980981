import { customersClient } from '@/api/api';
import { EnrichedCustomerModel } from '@/api/clients';
import { StoreBase } from '@/common/helpers/StoreBase';
import { Vue } from 'vue-property-decorator';

class CustomerStore extends StoreBase<EnrichedCustomerModel> {
    constructor() {
        super('load-customer');
    }

    protected loadInternal(id: string): Promise<EnrichedCustomerModel> {
        return customersClient.getEnrichedById(id);
    }
}

export const customerStore = Vue.observable(new CustomerStore());
