import { Auth0Plugin } from '@/auth/auth0';
import { getAuth0Connection } from '@/auth/getAuth0Connection';
import IconWrapper from '@/common/components/IconWrapper.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import { LoadingMixin } from '@/common/helpers/LoadingMixin';
import { registerServiceWorker } from '@/registerServiceWorker';
import { router } from '@/router/router';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import PortalVue from 'portal-vue';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import 'vue-class-component/hooks';
import VueCurrencyInput from 'vue-currency-input';
import VueMeta from 'vue-meta';
import VueScrollTo from 'vue-scrollto';
import Vuelidate from 'vuelidate';
import 'whatwg-fetch';
import App from './App.vue';
import i18n from './i18n';
import './styles/fontAwesomeIcons';
import './styles/index.scss';

export const errorHandler = new StackdriverErrorReporter();

(async function() {
    registerServiceWorker().catch(x => console.error(x));

    errorHandler.start({
        key: 'AIzaSyCa_YxqIqEkY8eqlBYE1SE2acoUYPjJpUI',
        projectId: 'ruedingerfluidmanagement',
        service: 'FluidManagementSPA',
    });

    Vue.config.productionTip = false;
    Vue.config.errorHandler = err => errorHandler.report(err);

    Vue.use(AsyncComputed);

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);
    Vue.component('FontAwesomeLayers', FontAwesomeLayers);
    Vue.component('IconWrapper', IconWrapper);

    Vue.use(VueCurrencyInput, {
        globalOptions: {
            currency: null as any,
            distractionFree: { hideGroupingSymbol: true },
            precision: { min: 0, max: 2 },
        },
    });

    Vue.use(Auth0Plugin, {
        domain: 'ruedinger.eu.auth0.com',
        clientId: 'cpWsWNRfv5UGlN3FVlpfls4SSAJ3WtkU',
        authorizationParams: {
            audience: 'fluid-management-api',
            scope: 'openid offline_access profile',
            responseType: 'token id_token',
            redirect_uri: window.location.origin,
            connection: await getAuth0Connection(),
        },
        onRedirectCallback(appState: any) {
            router.push(appState?.targetUrl ? appState.targetUrl : window.location.pathname);
        },
    });

    Vue.use(Buefy, {
        defaultIconComponent: 'IconWrapper',
        defaultIconPack: 'fas',
        defaultToastDuration: 2_500,
        defaultToastPosition: 'is-bottom',
    });

    Vue.use(Vuelidate);

    Vue.use(PortalVue);
    Vue.use(VueMeta);

    Vue.mixin(LoadingMixin);
    Vue.component('PageHeader', PageHeader);

    Vue.use(VueScrollTo);

    new Vue({
        router,
        i18n,
        render: h => h(App),
    }).$mount('#app');
})();
