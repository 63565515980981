import { baseUrl } from '@/api/baseUrl';
import { TenantInfoModel } from '@/api/clients';
import i18n from '@/i18n';

declare global {
    interface Window {
        tenant: TenantInfoModel
    }
}

export async function getAuth0Connection(): Promise<string | undefined> {
    const response = await fetch(baseUrl + '/tenant');
    window.tenant = await response.json();

    if (!localStorage.getItem('locale')) {
        const locale = window.tenant.culture.substring(0, 2);
        i18n.locale = locale;
        localStorage.setItem('locale', locale);
    }

    return window.tenant.connection;
}
