export async function doWithDelayedLoading(f: () => Promise<any>, setLoading: (loading: boolean) => void) {
    try {
        const workPromise = f();

        const resolvedFirst = await Promise.race([
            workPromise.then(() => 'work'),
            new Promise<string>(resolve => setTimeout(resolve, 750, 'timeout')),
        ]);

        if (resolvedFirst === 'timeout') {
            setLoading(true);
            await workPromise;
        }
    } finally {
        setLoading(false);
    }
}
