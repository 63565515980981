import { Vue } from 'vue-property-decorator';

class PresentationModeStore {
    isEnabled = false;

    toggle() {
        this.isEnabled = !this.isEnabled;
    }
}

export const presentationModeStore = Vue.observable(new PresentationModeStore());
