














































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FooterLayout extends Vue {
    get tenant() {
        return window.tenant;
    }
}
