import { Vue } from 'vue-property-decorator';

class DownloadStore {
    exportFileName = '';
    exportDataUrl = '';

    trigger(blob: Blob, name: string) {
        this.exportFileName = name;
        this.exportDataUrl = window.URL.createObjectURL(blob);

        requestAnimationFrame(() => {
            document.getElementById('downloadHelper')!.click();
            window.URL.revokeObjectURL(downloadStore.exportDataUrl);
        });
    }
}

export const downloadStore = Vue.observable(new DownloadStore());
