import { nonStaffSingleCustomerGuard } from '@/customers/helpers/nonStaffSingleCustomerGuard';
import { authGuard } from '@/router/authGuard';
import { routes } from '@/router/routes';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

});

router.beforeEach(authGuard);
router.beforeEach(nonStaffSingleCustomerGuard);
