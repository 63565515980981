









import { NavItem } from '@/layout/helpers/NavItem';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavigationItem extends Vue {
    @Prop({ type: Object, required: true })
    readonly item!: NavItem;

    async handleClickEvent() {
        setTimeout(() => {
            const parent: any = this.$parent;

            parent.closeMenu();

            if (parent.$data._isNavDropdown) {
                parent.$parent.closeMenu();
            }
        }, 100);
    }

    mounted() {
        this.$el.addEventListener('click', this.handleClickEvent);
    }

    beforeDestroy() {
        this.$el.removeEventListener('click', this.handleClickEvent);
    }
}
