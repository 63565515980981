


























import { customerStore } from '@/customers/stores/CustomerStore';
import { observer } from 'vue-mutation-observer';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { RawLocation, RouteRecord } from 'vue-router/types/router';

interface Breadcrumb {
    tag: string
    target: RawLocation
    icon: string
    name: string
    breadcrumb: string
    hide?: boolean
}

@Component({
    directives: { observer },
})
export default class Breadcrumbs extends Vue {
    @Ref('scrollContainer')
    readonly scrollContainer!: Vue;

    get items(): Breadcrumb[] {
        return this.$route.matched
            .filter(x => x.meta.breadcrumb)
            .map(route => ({
                tag: route.meta.staffOnly && !this.$auth.isStaff ? 'a' : 'router-link',
                target: { name: route.name, params: this.$route.params },
                icon: route.meta.icon,
                name: route.name!,
                breadcrumb: route.meta.breadcrumb,
                hide: this.shouldHide(route),
            }));
    }

    shouldHide(route: RouteRecord) {
        return (route.name === 'department' && !customerStore.value?.hasDepartments) ||
            (route.name === 'customers' && !this.$auth.isStaff);
    }

    async onItemsChanged() {
        await this.$nextTick();

        if (this.scrollContainer) {
            this.scrollContainer.$el.scrollLeft = this.scrollContainer.$el.scrollWidth;
        }
    }
}
