import { doWithDelayedLoading } from '@/common/helpers/loading';
import i18n from '@/i18n';
import { ToastProgrammatic } from 'buefy';
import { LocaleMessages } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';

interface LoadingOptions {
    noLoading?: boolean;
    delayLoading?: boolean;
}

declare module 'vue/types/vue' {
    interface Vue {
        isLoading: boolean

        withProgress(f: () => Promise<void>, description?: string | LocaleMessages, options?: LoadingOptions): Promise<void>
    }
}

export function showErrorToast(e: any, description?: string) {
    let message = description?.length ? i18n.t('failed', { description }) : (e instanceof Error ? e.message : e.toString());

    if (e.status === 400 && e.response) {
        message += `: ${e.response}`;
    }

    ToastProgrammatic.open({
        message,
        type: 'is-danger',
    });
}

@Component
export class LoadingMixin extends Vue {
    isLoading = false;

    async withProgress(f: () => Promise<void>, description?: string | LocaleMessages, options?: LoadingOptions) {
        try {
            if (!options?.delayLoading) {
                this.isLoading = true;
                try {
                    await f();
                } finally {
                    this.isLoading = false;
                }
            } else {
                await doWithDelayedLoading(f, loading => {
                    if (!options?.noLoading) {
                        this.isLoading = loading;
                    }
                });
            }
        } catch (e) {
            console.error(e);
            showErrorToast(e, description?.toString());
        }
    }
}
