












import { downloadStore } from '@/common/helpers/downloadStore';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    metaInfo(this: App) {
        const { title, breadcrumb } = this.$route.meta ?? {};

        const prefix = this.$t(title ?? breadcrumb) as string;
        const suffix = `${this.tenant.name} Fluid Management`;

        return {
            title: prefix,
            titleTemplate: prefix ? `%s | ${suffix}` : suffix,
            link: [
                { rel: 'shortcut icon', href: `/img/icons/${this.tenant.favicon}/favicon.ico` },
                { rel: 'icon', type: 'image/png', href: `/img/icons/${this.tenant.favicon}/favicon-32x32.png` },
            ],
        };
    },
})
export default class App extends Vue {
    downloadStore = downloadStore;

    get tenant() {
        return window.tenant;
    }
}
