import Vue from 'vue';
import { NavigationGuard } from 'vue-router';

export const authGuard: NavigationGuard = (to, from, next) => {
    if (to.meta?.nonAuthenticated) {
        next();
        return;
    }

    const authService = Vue.prototype.$auth;

    function fn() {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            return next();
        }

        // Otherwise, log in
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    const unwatch = authService.$watch('loading', (loading: boolean) => {
        if (!loading) {
            fn();
            unwatch();
        }
    });
};
