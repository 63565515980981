import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from './locales/de.json';
import en from './locales/en.json';

Vue.use(VueI18n);

export default new VueI18n({
    locale: localStorage.getItem('locale') || navigator.language,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: { de, en },
});
